import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Layout, Form } from 'antd';
import Image from 'next/image';

// context
import { useAuthState } from '@context/auth';

// components
import MainLayout from '@components/layout/main';
import Alert from '@components/common/alert';
import TextField from '@components/common/TextField';
import Button from '@components/common/button';
import CheckBox from '@components/common/CheckBox';

// services
import AuthService from '@services/auth';
import AuthTokenStorageService from '@services/AuthTokenStorageService';

const Index = () => {
  // const [scrollPosition, setScrollPosition] = useState(0);
  // const [headerColor, setheaderColor] = useState('#2C2A31');

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };

  // useEffect(() => {
  //   if (scrollPosition > 30) setheaderColor('#6533F4');
  //   else setheaderColor('#2C2A31');
  // }, [scrollPosition]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // console.log(headerColor);

  return (
    <MainLayout>
      <Layout>
        <div className="h-full justify-center items-center bg-cover bg-center">
          <div className="justify-center items-center text-center flex h-96 bg-imgBG">
            <div>
              <span className="text-7xl font-bold text-white">
                Charge inspiration
              </span>
              <div className="justify-end flex mr-[4px]">
                <span className="text-xs font-bold text-[#FF6464]">
                  Урам зоригоо цэнэглэ
                </span>
              </div>
              <div className="mt-[20px]">
                <a
                  href="https://apps.apple.com/mn/app/ask-y/id6443587454"
                  target="_blank"
                >
                  <button className="bg-primary hover:bg-primary/70 pr-[20px] pl-[20px] p-[8px] rounded-full text-white font-semibold text-xs mr-1">
                    App store
                  </button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.bytelinks.asky"
                  target="_blank"
                >
                  <button className="bg-primary hover:bg-primary/70 pr-[20px] pl-[20px] p-[8px] rounded-full text-white font-semibold text-xs ml-1">
                    Play store
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* Апп-ын тухай */}
          <section className="w-[1100px] m-auto mt-[20px]">
            <span className="text-[gray] text-xs font-bold">•Апп-ын тухай</span>
            <div className="w-[1100px] m-auto h-[366px] bg-white rounded-md flex mt-[8px]">
              <Image
                src={`/assets/web/appImg.svg?1`}
                alt="avatar"
                width={585}
                height={367}
                layout="responsive"
              />
              <div className="w-[480px] items-center flex justify-center flex">
                <div className="">
                  <div className="font-bold text-3xl">
                    ASK•Y<span className="text-lg">гэж юу вэ?</span>
                  </div>
                  <div className="w-[360px] text-xs text-[gray] font-medium">
                    Шинийг эрэлхийлж, сурч мэдэж буй хэн бүхэнд асуудал тулгарах
                    нь элбэг. Гэвч асуудал бүрт шийдэл бий. Бид ерөнхий
                    боловсролын сургуулийн сурагчдынхаа сурч, боловсрох үйлсэд
                    багшийн зөвлөгөө, тусламж шаардлагатай үед үргэлж хамт байж,
                    илүү урам зоригтойгоор суралцахад туслах болно.
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Багш нар */}
          <section className="w-[1100px] m-auto mt-[20px]">
            <span className="text-[gray] text-xs font-bold">•Багш нар</span>
            <div className="flex justify-between">
              <div className="w-[550px] m-auto h-[366px] bg-white rounded-md flex mt-[8px] ml-[0px]  justify-center">
                <div className="items-center flex">
                  <div className="">
                    <div className="font-bold text-3xl">
                      Багш нар<span className="text-lg"></span>
                    </div>
                    <div className="w-[313px] text-xs text-[gray] font-medium mb-[10px]">
                      Эх орныхоо ирээдүй болсон сурагчдынхаа сурч, боловсрох
                      үйлсэд ур чадвар, сэтгэл оюунаа зориулан боловсролтой,
                      хүчирхэг ирээдүйн төлөө хамтран ажиллаж байгаа багш нартаа
                      талархъя.
                    </div>
                    <button className="bg-primary pr-[20px] pl-[20px] p-[8px] rounded-full text-white font-semibold text-xs mr-1">
                      Дэлгэрэнгүй
                    </button>
                  </div>
                </div>
              </div>
              <Image
                src={`/assets/web/teachers.svg?4`}
                alt="avatar"
                width={542}
                height={382}
              />
            </div>
          </section>
          {/* Төлбөрийн мэдээлэлр */}
          <section className="w-[1100px] m-auto mt-[20px]">
            <span className="text-[gray] text-xs font-bold">
              •Төлбөрийн мэдээлэл
            </span>
            <div className="w-[1100px] m-auto h-[366px] bg-white rounded-md flex mt-[8px] items-center justify-center">
              <div className="bg-cashe w-[960px] h-[169px]"></div>
            </div>
          </section>
        </div>
      </Layout>
    </MainLayout>
  );
};

export default Index;
