import Head from 'next/head';
import Link from 'next/link';
import styles from './main.module.scss';

export default function MainLayout({ children }) {
  // console.log('MainLayout', children);
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <meta name="description" content="Asky Y edtech" />
        <meta name="keywords" content="Aсквая эдтэч" />
        <meta
          property="og:title"
          key="ogtitle"
          content="Ask Y  - Home working app"
        />
        <meta property="og:locale" key="oglocale" content="mn_MN" />
        <meta property="og:phone_number" content="75154444" />
        <meta property="og:locality" content="Ulaanbaatar" />
        <meta property="og:region" content="UB" />
        <meta property="og:country-name" content="Mongolia" />
        <meta property="og:type" key="ogtype" content="website" />
        <meta property="og:description" key="ogdesc" content="Asky Y edtech" />
        <title>ASK Y</title>
      </Head>
      <main className={styles.main}>
        <div
          className={`w-full h-14 justify-center m-auto items-center flex bg-[#161616] fixed transition duration-500 ease-in-out z-10`}
        >
          <div className="w-[1100px] justify-between flex">
            <Link href="/">
              <div className="text-white bg-logo w-[75px] h-[22px]"></div>
            </Link>
            <div>
              <Link href="/">
                <span className="text-white/70 hover:text-white mr-3.5 ml-3.5 cursor-pointer font-semibold text-xs">
                  Бидний тухай
                </span>
              </Link>
              <Link href="/">
                <span className="text-white/70 hover:text-white mr-3.5 ml-3.5 cursor-pointer font-semibold text-xs">
                  Ask Y
                </span>
              </Link>
              <Link href="/">
                <span className="text-white/70 hover:text-white mr-3.5 ml-3.5 cursor-pointer font-semibold text-xs">
                  Багш нар
                </span>
              </Link>
              <Link href="/">
                <span className="text-white/70 hover:text-white mr-3.5 ml-3.5 cursor-pointer font-semibold text-xs">
                  Төлбөрийн мэдээлэл
                </span>
              </Link>
            </div>
            <div className="text-white flex items-center  w-[80px] justify-between">
              <a
                href="https://www.facebook.com/profile.php?id=100068105243894"
                target="_blank"
              >
                <svg
                  className="opacity-50 hover:opacity-100 cursor-pointer"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 9C18 4.02991 13.9701 0 9 0C4.02991 0 0 4.02991 0 9C0 13.492 3.29063 17.2153 7.59375 17.8911V11.6024H5.30799V9H7.59375V7.01719C7.59375 4.76196 8.93772 3.51522 10.9933 3.51522C11.978 3.51522 13.0082 3.6912 13.0082 3.6912V5.90625H11.8728C10.7554 5.90625 10.4058 6.59973 10.4058 7.3125V9H12.9017L12.5032 11.6024H10.4062V17.8919C14.7094 17.2165 18 13.4932 18 9V9Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a href="https://www.youtube.com" target="_blank">
                <svg
                  className="opacity-50 hover:opacity-100 cursor-pointer"
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.5049 2.7911C19.7547 3.11708 18.9609 3.33185 18.1486 3.4286C19.0022 2.92876 19.6442 2.13467 19.9541 1.19527C19.147 1.66718 18.2655 1.9983 17.3474 2.17444C16.9608 1.76924 16.4958 1.4469 15.9807 1.227C15.4656 1.0071 14.9112 0.894236 14.3511 0.895271C12.0836 0.895271 10.2486 2.7036 10.2486 4.93277C10.247 5.24284 10.2826 5.55199 10.3545 5.8536C8.7285 5.77738 7.13635 5.3626 5.67988 4.63579C4.2234 3.90899 2.93467 2.88617 1.89613 1.63277C1.53179 2.24699 1.3391 2.94779 1.33822 3.66194C1.33822 5.06194 2.06863 6.29944 3.17155 7.02444C2.5181 7.00893 1.87805 6.83598 1.30572 6.52027V6.57027C1.30572 8.5286 2.72238 10.1578 4.59738 10.5286C4.24479 10.6226 3.88145 10.6702 3.51655 10.6703C3.25762 10.6707 2.99929 10.6456 2.7453 10.5953C3.26655 12.1994 4.78363 13.3661 6.58072 13.3994C5.12046 14.5248 3.32764 15.1331 1.48405 15.1286C1.15681 15.1281 0.829868 15.1086 0.504883 15.0703C2.38037 16.2679 4.56049 16.9013 6.78572 16.8953C14.3424 16.8953 18.4707 10.7411 18.4707 5.4036C18.4707 5.2286 18.4661 5.0536 18.4578 4.88277C19.2591 4.3128 19.9523 3.60448 20.5049 2.7911V2.7911Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a href="https://www.twitter.com" target="_blank">
                <svg
                  className="opacity-50 hover:opacity-100 cursor-pointer"
                  width="21"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.5318 4.20779C20.5318 2.44976 19.2386 1.03552 17.6408 1.03552C15.4764 0.934331 13.2691 0.895264 11.0134 0.895264H10.3102C8.05989 0.895264 5.84867 0.934331 3.68433 1.03591C2.09038 1.03591 0.797252 2.45796 0.797252 4.21599C0.699583 5.6064 0.658172 6.9972 0.660516 8.388C0.656609 9.7788 0.700886 11.1709 0.793345 12.5643C0.793345 14.3223 2.08648 15.7483 3.68043 15.7483C5.95415 15.8538 8.28648 15.9007 10.6579 15.8968C13.0332 15.9046 15.359 15.8551 17.6353 15.7483C19.2332 15.7483 20.5263 14.3223 20.5263 12.5643C20.6201 11.1696 20.663 9.7788 20.6591 8.3841C20.668 6.9933 20.6255 5.60119 20.5318 4.20779ZM8.74747 12.2205V4.54377L14.4123 8.38019L8.74747 12.2205Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        {children}
        <div className="w-full bg-[#505050] m-auto mt-[60px]">
          <div className="w-[1100px] m-auto h-[200px] text-white flex justify-between items-center">
            <div className="flex">
              <div className="bg-flogo w-[133px] h-[49px]"></div>
              <div className="w-[1px] h-[64px] bg-[#B1B1B1] ml-[10px] mr-[10px]"></div>
              <div className="w-[270px] text-xs text-[#B1B1B1]">
                Монгол улс, Улаанбаатар хот Чингэлтэй дүүрэг, 4-р хороо Бага
                тойруу 26, Сүхбаатарын гудамж 8-5 Пийс Бьюлдинг 5 давхар 505
                тоот (Peace Building)
              </div>
            </div>
            <div className="justify-end">
              <Link href="terms">
                <div className="text-white/70 hover:text-white justify-end flex text-xs">
                  Үйлчилгээний нөхцөл
                </div>
              </Link>
              <div className="justify-end flex">Утас: 72700901</div>
              <div className="justify-end flex">Мэйл хаяг: info@asky.mn</div>
              <div className="text-white/70 text-xs">
                © 2022 Ask Y - All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
